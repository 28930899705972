import React from 'react'

const Service = () => {
  const data = [
    {
      title: 'Who I Am And What I Do',
      desc1:
        'Our comprehensive service list includes International Investments, Supply Chain Management and Development, Global Expansion, Export Orientation, Start-up Facilitation and Business Acquisitions.',
      desc2:
        'Cutting edge technology and futuristic vision drives our strategy formation and successful implementation in all our domains.',
      desc3:
        'We understand our clients merits and move one step at a time to provide scope of up-gradation and achieve sustainable growth whilst building and maintaining long term relationships.',
      cover: './images/1.jpeg',
    },
  ]
  return (
    <>
      <section className='about topMarign'>
        <div className='container flex'>
          {data.map((value) => {
            return (
              <>
                <div className='left mtop'>
                  <div className='heading'>
                    <h3>About Lyfpower</h3>
                    <h1>{value.title}</h1>
                  </div>

                  <p>{value.desc1}</p>
                  <p>{value.desc2}</p>
                  <p>{value.desc3}</p>
                </div>
                <div className='right'>
                  <div className='img'>
                    <img src={value.cover} alt='' />
                  </div>
                </div>
              </>
            )
          })}
        </div>
      </section>
    </>
  )
}

export default Service

import React from 'react'
import { blog } from '../../../dummydata'
import './footer.css'

const Footer = () => {
  return (
    <>
      <section className='newletter'>
        <div className='container flexSB'>
          <div className='left row'>
            <h1>
              Stay tune and get the latest update about all the activity of
              Lyfpower
            </h1>
          </div>
          <div className='right row'>
            <input type='text' placeholder='Enter email address' />
            <i className='fa fa-paper-plane'></i>
          </div>
        </div>
      </section>
      <footer>
        <div className='container padding'>
          <div className='box logo'>
            <h1>LYFPOWER</h1>
            <span>Communicate. Collaborate. Succeed</span>
            <p>
              Lyfpower solves each client issues by providing a comprehensive
              approach backed by our team of experienced
              professionals.Opportunity to work on the most pressing issues for
              clients.Lyfpower works with the variety of projects and Fullfil
              the demands of the clients.
            </p>

            <i className='fab fa-facebook-f icon'></i>
            <i className='fab fa-twitter icon'></i>
            <i className='fab fa-instagram icon'></i>
          </div>

          <div className='box last'>
            <h3>Address</h3>
            <ul>
              <li>
                <i className='fa fa-map'></i>
                Unit 231/8 2250 Bovaird Dr E 9 190, Brampton, ON L6R 3J5
              </li>
              <li>
                <i className='fa fa-phone-alt'></i>
                43 72 337 777
              </li>
              <li>
                <i className='fa fa-paper-plane'></i>
                info@lyfpower.com
              </li>
            </ul>
          </div>
          <div className='legal'>
            <p>
              All rights reserved ©2022|<i className='fa fa-heart'></i> Designed
              by SoftcoPlus
            </p>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer

import React from 'react'
import Header from './components/common/heading/Header'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './components/home/Home'
import About from './components/about/About'
import './App.css'
import Contact from './components/contact/Contact'
import Team from './components/team/Team'
import Footer from './components/common/footer/Footer'
import Blog from './components/blog/Blog'
import Pricing from './components/pricing/Pricing'
import Black from './components/it/Black'
import Software from './components/software/Software'
import Consultant from './components/consultant/Consultant'
import Brampton from './components/brampton/Brampton'

function App() {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/team' element={<Team />} />
          <Route path='/journal' element={<Blog />} />
          <Route path='/it' element={<Black />} />
          <Route path='/software' element={<Software />} />
          <Route path='/consultant' element={<Consultant />} />
          <Route path='/brampton' element={<Brampton />} />

          <Route path='/services' element={<Pricing />} />
        </Routes>
        <Footer />
      </Router>
    </>
  )
}

export default App

import React from 'react'

const Consultant = () => {
  const data = [
    {
      title: 'Software architecture assessment & redesign ',
      desc1:
        'Identifying flaws in a software architecture affecting performance and security, limiting software scalability. Redesigning the architecture with a focus on resilience, quick changes and optimized maintenance costs.',
      desc2:
        'Assessing your software and validating its compliance with industry laws and regulations. Listing the requirements to license a software product.',
    },
  ]
  return (
    <>
      <section className='about topMarign'>
        <div className='container flex'>
          {data.map((value) => {
            return (
              <>
                <div className='left mtop'>
                  <div className='heading'>
                    <h1>{value.title}</h1>
                  </div>

                  <p>{value.desc1}</p>
                  <p>{value.desc2}</p>
                  <p>{value.desc3}</p>
                </div>
              </>
            )
          })}
        </div>
      </section>
    </>
  )
}

export default Consultant

import React from 'react'
import HAbout from './HAbout'
import AboutCard from '../about/AboutCard'
import Hero from './hero/Hero'
import Testimonal from './testmonial/Testmonial'
import Hblog from './Hblog'
import TeamCard from '../team/TeamCard'

const Home = () => {
  return (
    <>
      <Hero />
      <AboutCard />

      <section className='team padding'>
        <div className='container grid'>
          <TeamCard />
        </div>
      </section>
      <Testimonal />
      <Hblog />
    </>
  )
}

export default Home

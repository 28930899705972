const ServicesData = [
  {
    id: 1,
    title: 'Global Customer Acquisition & Export',
    desc: 'Global Customers Acquisition & Export Services are a source of pride and provide exemplary results for partners worldwide.',
    cover: '../../images/service/1.jpg',
  },
  {
    id: 2,
    title: 'supply chain',
    desc: 'understands the importance of an efficient supply chain to ensure timely delivery of raw materials at the manufacturing plant.',
    cover: '../../images/service/2.jpg',
  },
  {
    id: 3,
    title: 'Sales & Marketing',
    desc: 'sales and marketing experts analyze the potential target marketplace, initialize the discussions with the target market thereby increasing your global footprint.',
    cover: '../../images/service/3.jpg',
  },
  {
    id: 4,
    title: 'International Investment Services',
    desc: 'Harmonious growth across the international platform forms the key success factor. It provides partners and accomplishing continuous development.',
    cover: '../../images/service/8.jpg',
  },
  {
    id: 5,
    title: 'Startup Facilitation Services',
    desc: 'Research shows that 90% of start-ups fail due to lack of expert support and tactical strategy in initial stages of the business life cycle.  Incubation Team provides the much-needed expertise and resources to help you in your journey to success.',
    cover: '../../images/service/5.jpg',
  },
  {
    id: 6,
    title: 'Foreign Investment Services',
    desc: ' Foreign investment team aims to increase the investment in domestic industries for boosting production and technological capabilities.',
    cover: '../../images/service/6.jpg',
  },
  {
    id: 7,
    title: 'Market Research & Statistical Analysis',
    desc: ' MR & SA division concentrates on deep study of current market trends, World economy and potentially profitable sectors (PPS) to provide cutting edge insights to our clients.',
    cover: '../../images/service/7.jpg',
  },
]

export default ServicesData

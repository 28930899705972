export const homeAbout = [
  {
    id: 1,
    cover: 'https://img.icons8.com/dotty/80/000000/storytelling.png',
    title: 'Workflows That Work',
    desc: 'Lyfpower helps all businesses not only reach, but exceed their goals. Our experience helps us lay out a strategy that perfectly fits our clients. This collaboration is essential for the successful transition from strategy, to plan, to action.',
  },
  {
    id: 1,
    cover: 'https://img.icons8.com/ios/80/000000/diploma.png',
    title: 'All-In-One Solution',
    desc: 'On every project we undertake, our most important objective is to relate with clients and understand their core necessities and goals. Below are some examples of the work we’ve done - take a look, and get in touch to learn more.',
  },
  {
    id: 1,
    cover: 'https://img.icons8.com/ios/80/000000/athlete.png',
    title: 'Comprehensive Customer Support',
    desc: 'We are committed to solving complex business challenges using the latest technology and data resources, combined with our creative and collaborative strategic approach. Learn more about some of our talented professionals below.',
  },
  {
    id: 1,
    cover: 'https://img.icons8.com/ios/80/000000/athlete.png',
    title: 'Smart Automation Tools',
    desc: 'We have the scope necessary to provide the solutions you need, and the support to make your goals a reality. We look beyond the obvious to find innovative ways of solving existing and future challenges. Reach out if you like to join our list of satisfied clients.',
  },
]
export const awrapper = [
  {
    cover:
      'https://img.icons8.com/external-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto/80/ffffff/external-graduation-education-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto.png',
    data: '3,000',
    title: 'PRODUCT RANGE EXPERTISE',
  },

  {
    cover: 'https://img.icons8.com/ios/80/ffffff/athlete.png',
    data: '320',
    title: 'GLOBAL NETWORK',
  },
  {
    cover:
      'https://img.icons8.com/external-outline-icons-maxicons/80/ffffff/external-calender-insurance-outline-outline-icons-maxicons.png',
    data: '1,000',
    title: 'REVENUE GENERATED',
  },
  {
    cover: 'https://img.icons8.com/ios/80/ffffff/macbook-idea--v3.png',
    data: '100%',
    title: 'GLOBAL CLIENT RETENTION',
  },
]
export const coursesCard = [
  {
    id: 1,
    cover: '../images/courses/',
    coursesName: 'Introducing to Software Engineering',
    courTeacher: [
      {
        dcover: './images/back.webp',
        name: '',
        totalTime: '50 lectures (190 hrs)',
      },
    ],
    priceAll: '$100 All Course',
    pricePer: '$15 per month',
  },
  {
    id: 2,
    cover: '../images/courses/',
    coursesName: 'Enhancing Adobe Photoshop CC 2020 Skills',
    courTeacher: [
      {
        dcover: './images/back.webp',
        name: '',
        totalTime: '30 lectures (125 hrs)',
      },
    ],
    priceAll: '$200 All Course',
    pricePer: '$25 per month',
  },
  {
    id: 3,
    cover: '../images/courses/',
    coursesName: 'HTML, CSS, and Javascript for Web Developers',
    courTeacher: [
      {
        dcover: './images/back.webp',
        name: '',
        totalTime: '50 lectures (50 hrs)',
      },
    ],
    priceAll: '$50 All Course',
    pricePer: '$5 per month',
  },
  {
    id: 4,
    cover: '../images/courses/',
    coursesName: 'Introducing to Programming with WordPress',
    courTeacher: [
      {
        dcover: './images/back.webp',
        name: '',
        totalTime: '20 lectures (20 hrs)',
      },
    ],
    priceAll: '$30 All Course',
    pricePer: '$3 per month',
  },
  {
    id: 5,
    cover: '../images/courses/',
    coursesName: 'Introducing to Programming with ReactJS',
    courTeacher: [
      {
        dcover: './images/back.webp',
        name: '',
        totalTime: '100 lectures (150 hrs)',
      },
    ],
    priceAll: '$300 All Course',
    pricePer: '$30 per month',
  },
  {
    id: 6,
    cover: '../images/courses/c6.png',
    coursesName: 'Learn Frontend Programming Language',
    courTeacher: [
      {
        dcover: './images/back.webp',
        name: '',
        totalTime: '200 lectures (300 hrs)',
      },
    ],
    priceAll: '$500 All Course',
    pricePer: '$80 per month',
  },
  {
    id: 7,
    cover: '../images/courses/c7.png',
    coursesName: 'Introducing to with HTML / CSS',
    courTeacher: [
      {
        dcover: './images/back.webp',
        name: 'by Price',
        totalTime: '20 lectures (50 hrs)',
      },
    ],
    priceAll: '$20 All Course',
    pricePer: '$2 per month',
  },
  {
    id: 8,
    cover: '../images/courses/c8.png',
    coursesName: 'Introducing to with JAVA',
    courTeacher: [
      {
        dcover: './images/back.webp',
        name: 'by Petter',
        totalTime: '80 lectures (200 hrs)',
      },
    ],
    priceAll: '$200 All Course',
    pricePer: '$40 per month',
  },
  {
    id: 9,
    cover: '../images/courses/c9.png',
    coursesName: 'Introducing to with HTML / CSS / JAVASCRIPT',
    courTeacher: [
      {
        dcover: './images/back.webp',
        name: 'by Petter',
        totalTime: '50 lectures (50 hrs)',
      },
    ],
    priceAll: '$20 All Course',
    pricePer: '$3 per month',
  },
]
export const online = [
  {
    cover: './images/courses/online/o1.png',
    hoverCover: './images/courses/online/o1.1.png',
    courseName: 'UI/UX Design Courses',
    course: '25 Courses',
  },
  {
    cover: './images/courses/online/o2.png',
    hoverCover: './images/courses/online/o2.1.png',
    courseName: 'Art & Design',
    course: '25 Courses',
  },
  {
    cover: './images/courses/online/o3.png',
    hoverCover: './images/courses/online/o3.1.png',
    courseName: 'Computer Science',
    course: '10 Courses',
  },
  {
    cover: './images/courses/online/o4.png',
    hoverCover: './images/courses/online/o4.1.png',
    courseName: 'History & Archeologic',
    course: '15 Courses',
  },
  {
    cover: './images/courses/online/o5.png',
    hoverCover: './images/courses/online/o5.1.png',
    courseName: 'Software Engineering',
    course: '30 Courses',
  },
  {
    cover: './images/courses/online/o6.png',
    hoverCover: './images/courses/online/o6.1.png',
    courseName: 'Information Software',
    course: '60 Courses',
  },
  {
    cover: './images/courses/online/o7.png',
    hoverCover: './images/courses/online/o7.1.png',
    courseName: 'Health & Fitness',
    course: '10 Courses',
  },
  {
    cover: './images/courses/online/o8.png',
    hoverCover: './images/courses/online/o8.1.png',
    courseName: 'Marketing',
    course: '30 Courses',
  },
  {
    cover: './images/courses/online/o9.png',
    hoverCover: './images/courses/online/o9.1.png',
    courseName: 'Graphic Design',
    course: '80 Courses',
  },
  {
    cover: './images/courses/online/o10.png',
    hoverCover: './images/courses/online/o10.1.png',
    courseName: 'Music',
    course: '120 Courses',
  },
  {
    cover: './images/courses/online/o11.png',
    hoverCover: './images/courses/online/o11.1.png',
    courseName: 'Business Administration',
    course: '17 Courses',
  },
  {
    cover: './images/courses/online/o12.png',
    hoverCover: './images/courses/online/o12.1.png',
    courseName: 'Web Management',
    course: '17 Courses',
  },
]
export const team = [
  {
    cover: './images/team/20.jpg',
    name: ' Adrian Molises',
    work: 'DEVELOPER AND LEAD INSTRUCTOR',
  },
  {
    cover: './images/team/12.jpg',
    name: ' Arthur MaGregor',
    work: 'DEVELOPER AND LEAD INSTRUCTOR',
  },
  {
    cover: './images/team/t1.jpg',
    name: ' Anna Hanzen',
    work: 'Business Analyst',
  },
  {
    cover: './images/team/Master.webp',
    name: ' Brian Wooden',
    work: 'Software INSTRUCTOR',
  },
  {
    cover: './images/team/40.jpg',
    name: 'Shri Nath',
    work: 'DEVELOPER  INSTRUCTOR',
  },
]
export const price = [
  {
    name: 'Global Customer Acquisitions & Export',
    desc: 'Grow your company exports by collaborating with right and long term customers globally. Propose your quality products to worldwide consumers.',
  },
  {
    name: 'Supply Chain Development',

    desc: 'Take advantage from ASEAN countries for best raw material to leverage your profits with global access to manufactured goods.',
  },
  {
    name: 'Sales & Marketing',

    desc: 'Get access to the latest verified information about specific industries and major players to collaborate with for upcoming business​. ',
  },
  {
    name: 'International Investment Support',

    desc: 'Invest in warehousing/ distribution centers at strategic locations to provide Just-in-Time facility to global customers.',
  },
  {
    name: 'Startup Facilitation Services',

    desc: 'Bust the myth that says export is for established domestic businesses. Grow',
  },
  {
    name: 'Foreign Investment Support',

    desc: 'Join hands with global leaders to get an edge over competitors showcasing worldwide footprint',
  },
  {
    name: 'Market Research & Statistical Analysis',

    desc: 'Measure effectiveness of international supplier correlation and predict return on investments for global collaborations',
  },
]
export const faq = [
  {
    title: 'What are software outsourcing services?',
    desc: 'Software outsourcing is a remote service that is provided by software development companies. The service can consist of services ranging from full software or mobile application development, design development, testing, to project launch and project support.',
  },
  {
    title: 'What advantages of outsourcing your project to our team you get?',
    desc: 'Outsourcing your projects to us will grant you enough time to focus on growing your company as we carry out our services in the most efficient way for your business and without the need to involve you too much in this process. ',
  },
  {
    title: 'What is offshore software outsourcing?',
    desc: 'We are bound by the confidentiality agreement, and will be unable to share these details. A large number of full-time developers are a part of our pool, working on different in-house and client projects. At different times, the available resources are different and unique. You can be assured that only the best quality, trained and experienced resources will work on your project and meet your requirements.',
  },
  {
    title:
      ' Do your development resources make use of any version control repository?',
    desc: 'We use FogCreek Kiln source code and version control repository. In addition, we are experienced with Visual Source Safe, Team Foundation Server, and other popular version control systems.',
  },
  {
    title: 'What is the standard practice you follow for code comments?',
    desc: 'While we feel that providing comments for all public methods is a good habit, we comment only when it iss. For instance, we avoid commenting on codes that have the correct method name. Through this practice, we avoid over-commenting and create codes that are easy on the eyes.',
  },
  {
    title: 'How do you handle change requests from your customers?',
    desc: 'We have a standard procedure of handling change requests from our customers. Firstly, we seek their approval for any change request. After the customer approves the request, we do an impact analysis. This allows us to deliver the timelines and cost estimates to the customer. Once the customer approves the estimates, we implement the changes.',
  },
]
export const blog = [
  {
    id: 1,
    type: 'admin',
    date: 'JAN. 18, 2021',

    title: 'Build your Dream Software',
    desc: 'With 15K+ global network, you can mark global footprint acquiring International customers with every step put ahead with us',
    cover: '../images/blog/1.jpg',
  },
  {
    id: 2,
    type: 'admin',
    date: 'API. 25, 2022',

    title: 'Software selection',
    desc: 'our varied range of International services and get customized plan that our team executes for benefit of your organization.Forecast markets and sales potential accurately for better outreach and coverage.',
    cover: '../images/blog/2.jpg',
  },
  {
    id: 3,
    type: 'Admin',
    date: 'MAY. 15, 2022',

    title: 'Team augmentation',
    desc: 'Providing staff resources to complete the required skillset of any project role in your development team.Understand global customers’ behavior better and use segmentation for better targeting.',
    cover: '../images/blog/3.jpg',
  },
  {
    id: 4,
    type: 'admin',
    date: 'JAN. 02, 2022',
    com: '3 COMMENTS ',
    title: 'Project management consulting',
    desc: 'Setting up project processes and controls to keep project deliverables, schedules and costs on track. Our expertise in sectors stands prominent, so that only right solutions are provided by our global team.',
    cover: '../images/blog/4.jpg',
  },
  {
    id: 5,
    type: 'admin',
    date: 'DEC. 14, 2022',
    com: '3 COMMENTS ',
    title: 'Build your Dream Software & Engineering Career',
    desc: 'A small river named Duden flows by their place and supplies it with the necessary regelialia.',
    cover: '../images/blog/5.jpg',
  },
  {
    id: 6,
    type: 'user',
    date: 'JAN. 18, 2021',
    com: '12 COMMENTS ',
    title: 'Build your Dream Software & Engineering Career',
    desc: 'A small river named Duden flows by their place and supplies it with the necessary regelialia.',
    cover: '../images/blog/1.avif',
  },
]
export const testimonal = [
  {
    id: 1,
    name: 'Heena Sharma',

    desc: 'There are few things as rewarding as a successful collaboration that solves a challenge. Our team of professional business consultants use their years of experience and knowledge to create lasting and rewarding relationships with our business partners. Check out what our clients are saying about working with Lyfpower.',
    cover: './images/testo/1.jpg',
  },
  {
    id: 2,
    name: 'Tom  Parkmerced',

    desc: 'As top consultants in Ontario, we make sure to give our clients the utmost attention and dedication they deserve. We’re proud to showcase the following testimonials from some of our valued clients. Read their critiques to check out what they’ve said about us and see why your business should be working with us too. Contact us now to get started.',
    cover: './images/testo/a.jpg',
  },
  {
    id: 3,
    name: 'Vikas Kumar',

    desc: 'Since our founding, we have helped countless clients find solutions to their organizational challenges. Read on to learn what our clients have to say about their experiences working with us. If you would like to learn more about what we can do for your business, get in touch with us today to schedule a consultation',
    cover: './images/testo/it.jpg',
  },
]

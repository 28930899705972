import React from 'react'
import Back from '../common/back/Back'
import PriceCard from './PriceCard'
import './price.css'
import Faq from './Faq'
import Service from './Service'
import Best from './Best'

const Pricing = () => {
  return (
    <>
      <Back title='Lyfpower Services' />
      <Best />
      <section className='price padding'>
        <div className='container grid'>
          <PriceCard />
        </div>
      </section>
      <Faq />
    </>
  )
}

export default Pricing

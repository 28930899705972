import React from 'react'

const Brampton = () => {
  const data = [
    {
      title: 'Lyfpower Brampton ',
      desc1:
        'Auditing your business processes and planning improvements with software-only changes: workflow automation, facilitated collaboration within internal and external teams, increased visibility into process performance, quick reporting, and more.',
      desc2:
        'Setting up project processes and controls to keep project deliverables, schedules and costs on track. Enabling continuous software delivery to accelerate project development.',
    },
  ]
  return (
    <>
      <section className='about topMarign'>
        <div className='container flex'>
          {data.map((value) => {
            return (
              <>
                <div className='left mtop'>
                  <div className='heading'>
                    <h1>{value.title}</h1>
                  </div>

                  <p>{value.desc1}</p>
                  <p>{value.desc2}</p>
                  <p>{value.desc3}</p>
                </div>
              </>
            )
          })}
        </div>
      </section>
    </>
  )
}

export default Brampton

import React from 'react'
import Back from '../common/back/Back'
import AboutCard from './AboutCard'
import './about.css'
import Service from '../pricing/Service'
import Title from '../common/title/Title'

const About = () => {
  return (
    <>
      <Back
        subtitle='WELCOME TO LYFPOWER CONSULTING'
        title='
                     Communicate.
                     Collaborate. Succeed.'
      />

      <Service />
      <br></br>
      <br></br>
      <br></br>
      <AboutCard />
    </>
  )
}

export default About

import React from 'react'

const Software = () => {
  const data = [
    {
      title: 'About Software Consulting',
      desc1:
        'Software consulting services help plan and implement software improvements as well as staff software development projects with skilled resources.',
      desc2:
        'Auditing the way your current software performs and meets your business needs, planning improvements to detected flaws and inefficiencies. Suggested action points may include software modernization, refactoring, migration.',
    },
  ]
  return (
    <>
      <section className='about topMarign'>
        <div className='container flex'>
          {data.map((value) => {
            return (
              <>
                <div className='left mtop'>
                  <div className='heading'>
                    <h1>{value.title}</h1>
                  </div>

                  <p>{value.desc1}</p>
                  <p>{value.desc2}</p>
                  <p>{value.desc3}</p>
                </div>
              </>
            )
          })}
        </div>
      </section>
    </>
  )
}

export default Software

import React from 'react'

const Black = () => {
  const data = [
    {
      title: 'About IT consulting ',
      desc1:
        'IT consulting services are advisory services that help clients assess different technology strategies and, in doing so, align their technology strategies with their business or process strategies. These services support customers’ IT initiatives by providing strategic, architectural, operational and implementation planning. Strategic planning includes advisory services',
      desc2:
        'Operational assessment/benchmarking include services that assess the operating efficiency and capacity of a client’s IT environment.',
    },
  ]
  return (
    <>
      <section className='about topMarign'>
        <div className='container flex'>
          {data.map((value) => {
            return (
              <>
                <div className='left mtop'>
                  <div className='heading'>
                    <h1>{value.title}</h1>
                  </div>

                  <p>{value.desc1}</p>
                  <p>{value.desc2}</p>
                  <p>{value.desc3}</p>
                </div>
              </>
            )
          })}
        </div>
      </section>
    </>
  )
}

export default Black
